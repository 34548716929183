import "./App.css";
import { useState } from "react";

// A function to make requests to our Workers API using a query
const getImage = async (address) => {
  // The base URL for our API
  const url = "https://serverless-api.identicon.workers.dev";

  const resp = await fetch(url, {
    // Send a POST request
    method: "POST",
    // With a JSON-stringified body containing the query from our input
    body: JSON.stringify({ address }),
    // Set the `Content-type` header so our API knows that the request
    // is sending JSON
    headers: { "Content-type": "application/json" },
  });
  console.log(JSON.stringify({ address }));
  return resp.text();
};

function App() {
  // Store the query that we'll search for in a simple useState hook
  const [address, setAddress] = useState("");
  // Store the array of images from the API in an array
  const [image, setImage] = useState([]);

  // When the search button is clicked, make a request to the API
  // and set the response from it as our image array
  const search = async () => {
    const results = await getImage(address);
    setImage(results);
  };

  // When input#query changes, set query to the value of the input
  const updateAddress = (evt) => setAddress(evt.target.value);

  return (
    <div className="App">
      <div className="form">
        <input
          id="query"
          type="text"
          onChange={updateAddress}
          placeholder="Search query"
        />
        <button onClick={search}>Search</button>
      </div>

      <img src={image} />
    </div>
  );
}

export default App;
